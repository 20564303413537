<template>
    <van-cell-group inset>
        <div class="card add-node" center @click="addNode">
            <van-icon name="plus" size="20" />
        </div>

        <div v-for="item in nodeList" :key="item.id" class="card" @click="showNode(item)">
            <div class="card-header">
                <span>{{ formatTime(item.createdTime) }}</span>
                <span class="type">{{ getNodeName(item.type) }}</span>
            </div>

            <div class="detail">{{ item.remarks }}</div>

            <div class="card-footer">
                <span>{{ getStageName(item.stage) }}</span>
                <span>{{ item.operateUserName }}</span>
            </div>
        </div>

        <van-empty v-if="!nodeList.length" description="暂无记录" />
    </van-cell-group>
</template>

<script>
import { queryClientDetail, getNodeList } from "@/api/hoss/index";
import dayjs from "dayjs";
export default {
    data() {
        return {
            userInfo: {},
            showDialog: false,
            show: false,
            nodeData: {
                id: "",
                type: "",
                remarks: "",
            },
            nodeList: [],
            importantNodeType: [],
        };
    },
    async created() {
        this.clientId = this.$route.query.clientId;
        this.$toast.loading();
        await Promise.all([this.getUserInfo(), this.getList()]);
        const { importantNodeType, hossStage } = await this.$getDict();
        this.importantNodeType = importantNodeType.children;
        this.hossStage = hossStage.children;
        this.$toast.clear();
    },
    methods: {
        addNode() {
            const { clientId, stage } = this.$route.query;
            this.$router.push(`/hoss/addImportantNode?clientId=${clientId}&stage=${stage}`);
        },
        showNode(item) {
            this.$router.push(`/hoss/addImportantNode?id=${item.id}`);
            localStorage.setItem("ImportantNode_" + item.id, JSON.stringify(item));
        },
        async getUserInfo() {
            const { value } = await queryClientDetail(this.clientId);
            this.stage = value.stage;
            const stageName = this.getStageName(value.stage);
            this.userInfo = { ...value, activityId: "importantNode", stageName };
        },

        async getList() {
            const { value } = await getNodeList(this.clientId);
            this.nodeList = value.sort((a, b) => b.updatedTime - a.updatedTime);
        },
        formatTime(t) {
            return dayjs(t).format("YYYY-MM-DD HH:mm:ss");
        },
        getStageName(stage) {
            if ((!stage && stage != 0) || !this.hossStage?.length) return "";
            const stageItem = this.hossStage.find((item) => item.code == stage);
            return stageItem?.name || "";
        },
        getNodeName(code) {
            if ((!code && code != 0) || !this.importantNodeType.length) return "";
            const item = this.importantNodeType.find((item) => item.code == code);
            return item?.name || "";
        },
    },
};
</script>

<style scoped lang="less">
.van-cell-group {
    margin: 10px;
    padding: 0 10px;
}
.add-node {
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 !important;
}

.card {
    font-size: 12px;
    background-color: var(--blueCardBackgroundColor);
    padding: 10px;
    border-radius: 8px;
    margin: 10px 0;
    .card-footer,
    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .type {
            color: var(--red);
        }
    }
    .detail {
        color: var(--subTitleColor);
        line-height: 1.3em;
        height: 3.9em;
        margin: 5px 0;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .card-footer {
        color: var(--mainColor);
    }
}
</style>
